.status_model_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
    backdrop-filter: blur(4px); /* Optional blur for a frosted glass effect */
}

.status_modal_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    background-color: var(--background-default);;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.status_modal_container:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.status_modal_container .header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between items */
    gap: 10px;
    font-size: var(--heading-font-size);
    font-weight: bold;
    color: var(--logo-text);
    text-transform: uppercase;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Subtle bottom border */
    padding-bottom: 10px; /* Padding for header */
}

.status_modal_container .main_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: var(--text-font-size);
    color: var(--logo-text);
    padding: 10px; /* Padding for main content */
}

.status_modal_container .main_content div {
    font-size: var(--text-font-size);
}

.status_modal_container.error {
    border: 2px solid #f44336; /* Red border for error */
    background-color: var(--background-default); /* Keep existing background */
}

.status_modal_container.success {
    border: 2px solid #4CAF50; /* Green border for success */
    background-color: var(--background-default); /* Keep existing background */
}

.status_modal_container.loading {
    border: 2px solid #FFA500; /* Orange border for loading */
    background-color: var(--background-default); /* Keep existing background */
}

/* .icon-sticker {
    font-size: 1.5em;
} */
.icon-gif {
    width: 24px;
    height: 24px;
    margin-left: 8px;
}

.close-icon {
    cursor: pointer; /* Change cursor to pointer */
    color: var(--logo-text); /* Match the color with the text */
    font-size: 1.5rem; /* Adjust size as needed */
    transition: color 0.3s; /* Smooth color transition */
}

.close-icon:hover {
    color: #f44336; /* Change color on hover */
}